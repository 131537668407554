import { DocumentStatus, Post, SportId } from '@ui-kit/main-api-types';
import { PostCard } from '@ui-kit/main-frontend-ui-kit/dist/src/components/PostCard';
import { INT_LANGS } from '@ui-kit/main-frontend-ui-kit/dist/src/utils';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import {
	hasModeratorRole,
	getFormattedDate,
	countDifferenceBetweenDates,
	sendAnalytics,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import { useRouter } from 'next/router';

import { deletePost } from '@store/posts/actions';
import { useUserStore } from '@store/user';
import { BETTING_SPORT_URLS } from '@utils/bookie';
import { TRIBUNA_COM_URL } from '@utils/constants';
import { UserRoles } from '@utils/types';

interface IPostCardHOC {
	post: Post;
	showBlog?: boolean;
	showEditButton?: boolean;
	dataTestPrefix?: string;
}
export const PostCardHOC = ({
	post,
	showBlog = true,
	showEditButton = false,
	dataTestPrefix = '',
}: IPostCardHOC) => {
	const router = useRouter();
	const { locale } = router;
	const userStore = useUserStore(state => state);
	const userState = userStore.user;

	const usageLocale = INT_LANGS?.find(
		lang => post?.title?.[lang] || post?.structuredBody?.[lang],
	);

	const title =
		post?.title?.defaultValue ||
		post?.title?.[usageLocale] ||
		post?.structuredBody?.[usageLocale]?.[0]?.value?.elements?.[0]?.value?.text;

	const localizedStructuredDescription =
		post?.structuredDescription?.defaultValue ||
		post?.structuredDescription?.[locale];
	const blogDescription = localizedStructuredDescription?.length
		? ((localizedStructuredDescription?.[0]?.value as any)?.elements?.[0]?.value
				?.text as string)
		: '';

	const commentsCount = post?.commentsCount;
	const reactions = post?.reactions;
	const mainImageUrl = post?.mainPhoto?.url;
	const time =
		post?.status === DocumentStatus.Unpublished
			? post?.createdTime
			: post?.publicationTime;
	const commentTime = dayjs(time * 1000).toISOString();
	const { isMoreThanOneYear } = countDifferenceBetweenDates(commentTime);

	const formattedDate = getFormattedDate({
		isMoreThanOneYear,
		time,
		isItBlogPosts: true,
	});

	const blog = post?.blog;
	const status = post?.status;
	const label = post?.label;
	const displayAuthors = post?.displayAuthor;
	const authors = (
		displayAuthors?.[0]?.user?.id ? displayAuthors : post?.author
	)
		?.slice(0, 1)
		.map(author => author?.user);

	const blogHru = blog?.hru;
	const blogId = blog?.id;
	const blogLinkID = blogHru || blogId;
	const postHruOrId = post?.hru || post?.id;
	const mainSport = post?.mainSport;
	let postLink: string = null;
	if (post?.url) {
		postLink = post?.url;
	} else if (post.status === DocumentStatus.Unpublished) {
		postLink = `${TRIBUNA_COM_URL}${locale}/blogs/create-post/${postHruOrId}/`;
	} else if (mainSport?.id === SportId.Football) {
		postLink = `${TRIBUNA_COM_URL}${locale}/blogs/${postHruOrId}/`;
	} else {
		postLink = `${TRIBUNA_COM_URL}${locale}/${
			mainSport?.id === SportId.Bet
				? BETTING_SPORT_URLS?.[locale]
				: mainSport?.path
		}/blogs/${postHruOrId}/`;
	}

	const submitDeletion = async () => {
		await deletePost(post?.id);
		if (router.pathname.includes('created')) {
			router.push(
				`${TRIBUNA_COM_URL}${locale}/profile/${userState?.info?.id}/`,
			);
		} else {
			//TODO: change reload to smth better
			window?.location?.reload();
		}
	};

	const author = authors?.[0];

	const authorInfo = {
		nickname: author?.nickname || author?.name,
		id: author?.id,
		link: `${TRIBUNA_COM_URL}${locale}/profile/${author?.id}/`,
		avatar: author?.avatar?.webp || author?.avatar?.url,
	};

	const isShowBlog = showBlog && (!!blogId || !!authors?.[0]?.id);

	const isModeratorBlogs = hasModeratorRole(
		userState?.roles,
		UserRoles.ModeratorBlog,
	);

	const onPostEditClick = () => {
		sendAnalytics({
			category: 'to_editing',
			name: 'click',
			value: post?.id,
			label: 'post',
		});
	};

	return (
		<PostCard
			onPostEditClick={onPostEditClick}
			isVerticalCard={false}
			postLink={postLink}
			editPostLink={`${TRIBUNA_COM_URL}${locale}/blogs/create-post/${post?.id}/`}
			title={title}
			description={blogDescription}
			postImg={mainImageUrl}
			status={DocumentStatus.Published}
			commentsCount={commentsCount}
			dislikeCount={reactions?.dislikeCount}
			likeCount={reactions?.likeCount}
			label={label}
			formattedDate={formattedDate}
			isWidget={false}
			author={authorInfo}
			showEditButton={showEditButton}
			submitDeletion={submitDeletion}
			showBlog={isShowBlog}
			isDraft={status === DocumentStatus.Unpublished}
			onSubmitModeratorDeletionClick={submitDeletion}
			dataTestPrefix={`${dataTestPrefix}post-`}
			shouldAddModeratorMenu={isModeratorBlogs}
			shouldAddModeratorEditorOption={false}
		/>
	);
};

export default PostCardHOC;
